@import 'src/styles/colors_v2';
@import 'src/styles/colors';
@import 'src/styles/breakpoints';

html {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  color: $font-black;
}

:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: $font-black;
}

button {
  color: $font-black;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1 {
  font-weight: 900;
  font-size: 42px;
  line-height: 1.2;
  padding: 0;
  margin: 20px 0 10px;

  @media (max-width: ($lg - 1)) {
    font-size: 28px;
    margin: 10px 0 5px;
  }
}

h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 0 0 15px;

  @media (max-width: ($lg - 1)) {
    font-size: 24px;
    text-transform: none;
  }
}

h3 {
  font-size: 28px;
  line-height: 130%;
  margin: 25px 0;

  @media (max-width: ($lg - 1)) {
    font-size: 22px;
    margin: 15px 0;
  }
}

.h4 {
  font-size: 24px;
  line-height: 130%;
  margin: 25px 0;

  @media (max-width: ($lg - 1)) {
    font-size: 20px;
    margin: 15px 0;
  }
}

.mark-list li {
  position: relative;
  padding-left: 19px;
  margin: 15px 0;

  &::after {
    position: absolute;
    content: '';
    width: 9px;
    height: 9px;
    top: 5px;
    left: 0;
    background: $yellow;
    border-radius: 50%;
  }
}

.numerik-list {
  counter-reset: li;
}

.numerik-list li {
  position: relative;
  padding-left: 30px;
  margin: 15px 0;

  &::after {
    position: absolute;
    content: counter(li) '.';
    counter-increment: li;
    top: 0;
    left: 0;
    bottom: 0;
    font-weight: bold;
  }
}

.header-text {
  font-size: 14px;
  line-height: 1.2;

  @media (max-width: ($lg - 1)) {
    line-height: 150%;
  }
}

.text {
  font-size: 16px;
  line-height: 150%;
  margin: 15px 0;
}

.u-lock {
  overflow: hidden;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

button.slick-arrow {
  width: 40px;
  height: 40px;
  background: $green-shift;

  &:hover {
    background: darken($green-shift, 3);
  }

  &:focus {
    background: darken($green-shift, 7);
  }

  &::before {
    display: none;
  }
}

.slick-prev {
  left: -15px;
}

.slick-next {
  right: -15px;
}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1px;
  opacity: 0.9;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-inner {
  text-align: left;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid #b1b1b1;
  max-width: 350px;
  font-size: 14px;
  line-height: 150%;
  background: $green-opacity;
  color: $white;
  padding: 8px 12px;
  border-radius: 5px;
}
.rc-tooltip-arrow,
.rc-tooltip-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 0px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #b1b1b1;
}
.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  bottom: 1px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #ffffff;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15px;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: -8px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #b1b1b1;
  transform: rotate(90deg);
}
.rc-tooltip-placement-right .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
  left: 1px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: -5px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #b1b1b1;
}
.rc-tooltip-placement-left .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
  right: 1px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: -5px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #b1b1b1;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
  top: 1px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

/* custom */

.rc-tooltip {
  opacity: 1;
  max-width: 100%;
  padding: 0;
  line-height: normal;
  background: none;
  padding-bottom: 6px;
  transition: all 0.2s ease-in-out;
}

.rc-tooltip-hidden {
  display: block;
  opacity: 0 !important;
  pointer-events: none;
}

.rc-tooltip-inner a {
  color: #fff;
}

.rc-tooltip-arrow {
  background: none;
  border: none;
  width: 12px;
  height: 6px;
  background-image: url('/static/icons/tooltip-arrow.svg');
  background-repeat: no-repeat;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  bottom: 0;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 0;
}

.rc-tooltip--white {
  & div {
    text-overflow: initial;
    white-space: initial;
  }

  .rc-tooltip-inner {
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);

    a {
      color: #2f80ed;
    }
  }

  .rc-tooltip-arrow {
    display: none;
  }
}

.rc-tooltip--small {
  .rc-tooltip-inner {
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
  }
}

.rc-tooltip--medium {
  .rc-tooltip-inner {
    padding: 10px 12px;
    border-radius: 6px;
    font-size: 14px;
  }
}

.rc-tooltip--medium-left {
  .rc-tooltip-arrow {
    transform: rotate(90deg) translateY(3px);
  }

  .rc-tooltip-inner {
    padding: 10px 12px;
    border-radius: 6px;
    font-size: 14px;
  }
}

.rc-tooltip--big {
  .rc-tooltip-inner {
    padding: 16px;
    border-radius: 8px;
  }
}

.rc-tooltip--priceInput {
  .rc-tooltip-content {
    transform: translateY(20px);
  }

  .rc-tooltip-arrow {
    transform: translateY(6px);
  }

  // @media (max-width: ${BREAKPOINTS.XL}) {
  //   .rc-tooltip-content {
  //     transform: none;
  //   }

  //   .rc-tooltip-arrow {
  //     transform: none;
  //   }
  // }
}

.rc-tooltip-placement-left .rc-tooltip-inner {
  transform: translateX(-10px);
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  transform: translateX(-6px) rotate(-90deg);
}

.rc-tooltip--is-error {
  .rc-tooltip-inner {
    transform: translateX(-20px);
    padding: 12px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-image: url('/static/icons/exclamation-circle.svg');
    padding-left: 36px;
    background-position: 12px 13px;
    font-size: 14px;
  }

  .rc-tooltip-arrow {
    left: 7px !important;
  }
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  transform: rotate(180deg);
}

.react-select__control.react-select__control--is-focused {
  font-size: 16px !important;
}
